.scrollbar {
	float: left;
	height: 300px;
	max-width: 100%;
	background: rgb(242, 185, 174);
	overflow-y: scroll;
	margin-bottom: 25px;
}

.text-box {
	padding-left: 30px;
	display: block;
	margin-top: 30px;
	width: 45%;
	align-content: flex-start;
	margin-right: 5%;
}


.text-box2 {
	padding-right: 30px;
	display: block;
	width: 45%;
	float: right;
}

#style-4::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: rgb(242, 185, 174);
}

#style-4::-webkit-scrollbar {
	width: 10px;
	background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar-thumb {
	background-color: #000000;
	border: 2px solid #555555;
}

.btn5 {
	display: list-item;
	font-size: 10px;
	opacity: 0.75;
	border: none;
	width: 155px;
	text-align: center;
	padding: 15px;
	letter-spacing: 0.05rem;
	font-weight: 100;
	left: 30%;
	margin: 20px;
	color: rgb(242, 185, 174);
}

h4 {
	font-weight: 200;
}

.userUpdate {
	margin-left: -8%;
}

ul#special {
	margin-right: 30px;
}