.scrollbar3 {
	margin-top: 50px;
	margin-left: auto;
	margin-right: auto;
	height: 300px;
	max-width: 80%;
	overflow-y: scroll;
}

.text-box3 {
	display: block;
	margin-top: 10px;
	align-content: flex-start;
	margin-right: 5%;

}


#style-4::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #FDE6C6;
}

#style-4::-webkit-scrollbar {
	width: 10px;
	background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar-thumb {
	background-color: #000000;
	border: 2px solid #555555;
}


li {
	list-style: none;
	text-align: center;
}


@mixin btn-border-drawing($color: #ccc, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.25s) {
	box-shadow: inset 0 0 0 $width $color;
	color: rgb(26, 37, 63);
	transition: color $duration $duration/3;
	position: relative;

	&::before,
	&::after {
		border: 0 solid transparent;
		box-sizing: border-box;

		content: '';
		pointer-events: none;
		position: absolute;
		width: 0;
		height: 0;

		#{$vertical}: 0;
		#{$horizontal}: 0;
	}

	&::before {
		$h-side: if($horizontal=='left', 'right', 'left');

		border-#{$vertical}-width: $width;
		border-#{$h-side}-width: $width;
	}

	&::after {
		$v-side: if($vertical=='top', 'bottom', 'top');

		border-#{$v-side}-width: $width;
		border-#{$horizontal}-width: $width;
	}

	&:hover {
		color: $hover;

		&::before,
		&::after {
			border-color: $hover;
			transition: border-color 0s, width $duration, height $duration;
			width: 100%;
			height: 100%;
		}

		&::before {
			transition-delay: 0s, 0s, $duration;
		}

		&::after {
			transition-delay: 0s, $duration, 0s;
		}
	}
}

.draw-border {
	@include btn-border-drawing(rgb(242, 185, 174), rgb(242, 195, 174), 4px, bottom, right);
}

.draw-border-two {

	@include btn-border-drawing(none, rgb(26, 37, 63), 4px, bottom, right);
}



//=== Button styling, semi-ignore
.btn4 {
	display: list-item;
	font-size: 10px;
	opacity: 0.75;
	border: none;
	width: 155px;
	text-align: center;
	padding: 15px;
	letter-spacing: 0.05rem;
	font-weight: 100;
	left: 40%;
	margin: 20px;
	color: rgb(242, 185, 174);

}