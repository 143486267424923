body {
	background: rgb(26, 37, 63);
}

.hero {
	background: url(https://i.ibb.co/FY6w4Jm/2395318.jpg);
	background-size: cover;
	opacity: 0.87;
}

.logo {
	width: 10%;
	text-align: center;
	overflow: visible;
}

h5 {
	font-size: 50px;
	text-align: center;
	color: rgb(26, 37, 63);
	letter-spacing: 0.2em;
	font-weight: 100;
	margin-top: 20px;
	padding-top: 50px;
}

h1 {
	text-align: center;
	color: rgb(26, 37, 63);
	font-size: 20px;
	font-weight: 500;
	width: 60%;
	margin-left: 10px;
	padding-top: 20px;
}

.sign {
	color: rgb(26, 37, 63);

	display: flow-root;
	padding-left: 25%;
}

.hidden {
	display: none;
}


@mixin btn-border-drawing($color: #ccc, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.25s) {
	box-shadow: inset 0 0 0 $width $color;
	color: rgb(26, 37, 63);
	transition: color $duration $duration/3;
	position: relative;

	&::before,
	&::after {
		border: 0 solid transparent;
		box-sizing: border-box;

		content: '';
		pointer-events: none;
		position: absolute;
		width: 0;
		height: 0;

		#{$vertical}: 0;
		#{$horizontal}: 0;
	}

	&::before {
		$h-side: if($horizontal=='left', 'right', 'left');

		border-#{$vertical}-width: $width;
		border-#{$h-side}-width: $width;
	}

	&::after {
		$v-side: if($vertical=='top', 'bottom', 'top');

		border-#{$v-side}-width: $width;
		border-#{$horizontal}-width: $width;
	}

	&:hover {
		color: $hover;

		&::before,
		&::after {
			border-color: $hover;
			transition: border-color 0s, width $duration, height $duration;
			width: 100%;
			height: 100%;
		}

		&::before {
			transition-delay: 0s, 0s, $duration;
		}

		&::after {
			transition-delay: 0s, $duration, 0s;
		}
	}
}

.draw-border {
	@include btn-border-drawing(rgb(242, 185, 174), rgb(26, 37, 63), 4px, bottom, right);
}

.draw-border-two {

	@include btn-border-drawing(none, rgb(26, 37, 63), 4px, bottom, right);
}



//=== Button styling, semi-ignore
.btn {
	display: block !important;
	color: rgb(26, 37, 63) !important;
	height: 50px !important;
	font-size: 20px !important;
	width: 100px !important;
	letter-spacing: 0.07rem !important;
	font-weight: 100 !important;
	margin-top: 20px;
	margin-bottom: 20px;
	font-family: 'Ubuntu', sans-serif;
	box-sizing: border-box;
	border: 0 solid transparent;

}

.btn2 {
	display: block;
	font-size: 10px;
	opacity: 0.75;
	border: none;
	width: 65px;
	text-align: center;
	padding: 15px;
	letter-spacing: 0.05rem;
	font-weight: 600;
	margin-top: -8px;
	margin-bottom: 30px;
	color: rgb(26, 37, 63);

}

.hidden {
	display: none;
}


h2 {
	font-size: 10px;
	font-weight: 100;
	letter-spacing: 0.01em;
	color: rgb(26, 37, 63) !important;
}

.upButton {

	color: rgb(242, 185, 174);
}