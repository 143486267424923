.Nav {
	color:white;
	justify-content: center;
	display: flex;
	margin-top: 20px;
}

a {
	text-align: center;
	text-decoration: none;
	list-style-type: none;
	padding: 10px;
	margin-top: 40px;
	margin-right: 50px;
	font-size: 23px;
list-style: circle;
	color: white;
	opacity: 0.9;
}

